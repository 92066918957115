div.admin {
  display: flex;
  padding: 20px;
}

.admin-list {
  flex: 2 1 auto;
}
.admin-form {
  flex: 1 1 auto;
}
