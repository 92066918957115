.results {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
}

.result-item {
  line-height: 36px;
}

.searchterm {
  display: inline-flex;
  align-items: center;
  padding: 3px 17px;
  border-radius: 20px;
  font-weight: 400;
  text-transform: capitalize;
  background: #45ABFF;
  color:white;
}

.searchterm > span {
  margin-right: 10px;
}

.list {
 display: flex;
 flex-direction: column;
}

.xcircle:hover {
  color: #45ABFF;
  background:white;
  border-radius: 30px;
}  
