html, body {
  color: rgba(126, 125, 137, 1);
  font-size: 20px;
  line-height: 24px;
}


div.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  padding: 0 25px;
  padding-top: 25px;
}

div.content > * {
  width: 100%;
  flex: 1 1 auto;
}


/* RGB */
/*$ONYX: rgba(53, 52, 63, 1);*/
/*$TROLLEY_GREY: rgba(126, 125, 137, 1);*/
/*$ASH_GREY: rgba(178, 177, 198, 1);*/
/*$LIGHT_GRAY: rgba(214, 214, 214, 1);*/
/*$MEDIUM_PURPLE: rgba(133, 125, 224, 1);*/


/* Move to correct files when created */


.click {
  cursor: pointer;
}
