div.site-list {
  display: flex;
  flex-direction: column;
}

div.site {
  display: flex;
  flex: 1 1 auto;
  padding: 5px;
  margin: 5px;
  border: 3px solid rgba(178, 177, 198, 1);
  border-radius: 5px;
}

div.site.approved {
  border: 3px solid rgba(122, 199, 79, 1);
}

div.site.denied {
  border: 3px solid rgba(242, 95, 56, 1);
}

.site-details div input {
  width: 100%;
}

.site-details {
  flex: 1 1 80%;
}
.site-options {
  flex: 1 1 20%;
  display: flex;
  align-items: center;
}
.site-options > div {
  padding: 0 5px;
  cursor: pointer;
}
