div.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  /*background-color: rgba(126, 125, 137, 1);*/
}

div.nav a, div.nav p {
  color: rgba(88, 89, 98, 1);
  text-decoration: none;
  padding: 10px;
  font-weight: 500;
}

div.nav .active {
  color: #45ABFF;
}


/* RGB */
/*$ONYX: rgba(53, 52, 63, 1);*/
/*$TROLLEY_GREY: rgba(126, 125, 137, 1);*/
/*$ASH_GREY: rgba(178, 177, 198, 1);*/
/*$LIGHT_GRAY: rgba(214, 214, 214, 1);*/
/*$MEDIUM_PURPLE: rgba(133, 125, 224, 1);*/
