html a:focus,
html button:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.category-container {
  display: flex;
  flex-wrap: wrap;
}

.category-container > * {
  flex: 0 0 auto;
}

.category-list {
  display: flex;
  color: #45ABFF;
  flex-wrap: wrap;
}

.category-list p {
  border: 1px solid #45ABFF;
  padding: 3px 17px;
  border-radius: 20px;
  font-weight: 400;
  margin: 0 0 0 10px;
  font-size: 15px;
  text-transform: capitalize;
  transition: all ease-in-out .2s;
}
.api-category p {
  font-size: 15px;
}

.category-list p:hover {
  background: #45ABFF;
  color: white;
  transition: all ease-in-out .2s;
  cursor: pointer;
}

.category-list p + p {
  margin-left: 10px;
}

.category-list p.active {
  color: #45ABFF;
}

.category-dropdown {
  display: flex !important;
  overflow: auto;
  flex-flow: column wrap;
  line-height: 12px;
  font-size: 13px;
  height: 300px;
  align-content: flex-start;
  min-width: 60vw;
}

.dropdown-item {
  flex: 0 0 auto;
  width: 200px;
}

.dropdown-item:hover {
  color:white;
  background: #45ABFF !important;
}

.dropdown-text,
.dropdown-text:hover,
.dropdown-text:active,
.dropdown-text:focus,
.dropdown-text:visited {
  border: none !important;
  background: none !important;
  outline: none !important;
}

.dropdown-text {
  color: #fff;
  display:inline-block;
  font-size: 15px;
  padding: 5px;
}

.btn-group {
  background-color: #45ABFF;
  float: left;
  border-radius: 100px;
  outline: none;
  width: 150px;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .category-dropdown {
    display: flex !important;
    overflow: auto;
    flex-flow: column;
    line-height: 12px;
    font-size: 13px;
    height: 300px;
    align-content: flex-start;
    padding: 0;
    min-width: 80vw;
    width: 100%;
  }

  .dropdown-item {
    flex: 0 0 20%;
    width: 100%;
    font-size: 1.1rem;
  }

  .category-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .category-container > * {
    flex: 0 0 auto;
  }

  .btn-group {
    width: auto;
    margin-bottom: 10px;
  }

  .category-list {
    display: flex;
    justify-content: flex-start;
    color: #45ABFF;
    flex-wrap: wrap;
  }

  .category-list p {
    display: flex;
    min-width: 100px;
    justify-content: center;
    margin: 10px 0 0 10px;
    font-size: 15px;
    text-transform: capitalize;
    transition: all ease-in-out .2s;
  }
}

/*On screens that are 600px or less, set the background color to olive */
/*@media screen and (max-width: 600px) {*/
  /*.category-dropdown {*/
    /*display: flex !important;*/
    /*overflow: auto;*/
    /*flex-flow: column;*/
    /*line-height: 12px;*/
    /*font-size: 13px;*/
    /*height: 300px;*/
    /*align-content: flex-start;*/
    /*padding: 0;*/
    /*min-width: 80vw;*/
  /*}*/

  /*.dropdown-item {*/
    /*flex: 0 0 15%;*/
    /*width: 100%;*/
  /*}*/

/*}*/
