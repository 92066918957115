div.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 15px;
  margin-bottom: 12px;
  border: 1px solid rgba(89, 88, 98, 1);
  border-radius: 50px;
}

.search-input, .search-input input {
  border: none;
  font-size: 1.1rem;
  background: none;
  width: 100%;
  color: rgba(89,88,98,1);
  font-weight: 300;
}

input:focus {
  outline: none;
}

.search-button {
  color: rgba(89,88,98,1);
}
